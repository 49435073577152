// ----------------------------------------------------------------------



const account  = {
  displayName: 'maro',
  email: 'demo@minimals.cc',
  photoURL: '/static/mock-images/avatars/icon_monito_blackbay.png',
};

export default account;
