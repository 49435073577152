import * as React from 'react';
import './slider.css'
import $ from 'jquery'
import { Button } from '@mui/material';
import Logo from '../logo/Logo_mexbridge_oscuro.svg'
import { useEffect } from 'react';
import Icon1 from '../logo/Icono_folder.svg'
import Icon2 from '../logo/Icono_hojas.svg'
import Icon3 from '../logo/Icono_personas.svg'
import Icon4 from '../logo/Icono_candado.svg'
import Nav from 'src/components/Nav';
import { Link as RouterLink } from 'react-router-dom';
import BarraFooterIndex from 'src/components/BarraFooterIndex';


const Slider = () => {
  const Slider2=()=>{
  
    $( "#btnSocio" ).mouseenter(function() {
      $("#divPrin").removeClass("bgdefault")
      $("#divPrin").addClass("bgsocio")
    }).mouseleave(function() {
      $("#divPrin").removeClass("bgsocio")
      $("#divPrin").addClass("bgdefault")
    });
  
    $( "#btnEmpresa" ).mouseenter(function() {
      $("#divPrin").removeClass("bgdefault")
      $("#divPrin").addClass("bgempresa")
    }).mouseleave(function() {
      $("#divPrin").removeClass("bgempresa")
      $("#divPrin").addClass("bgdefault") 
     });
    
  }
  useEffect(() => {
    Slider2()
  }, [])
   /*
   const sliderData = [
  {
    id: 1,
    img: Private,
    tit: "PR",
    tulo: "IVATE",
    sub: 'SOLUTIONS',
    full: PrivateFull,
    titSvg: PriT
  },
  {
    id: 2,
    img: Customized,
    tit: "CU",
    tulo: "STOMIZED",
    sub: 'SOLUTIONS',
    full: CustomizedFull,
    titSvg: CusT
  },
  {
    id: 3,
    img: Professional,
    tit: "PR",
    tulo: "OFESSIONAL",
    sub: 'SOLUTIONS',
    full: ProfessionalFull,
    titSvg: ProT
  }
]
const navigate = useNavigate()
const ToLogin = () =>{
navigate('/sign-in', { replace: true });
}
*/

  return (
    <div className='fondo'>
      <Nav logo={Logo}/>
      <div className='contenidoslider container'>
        <h1 className='h1contenidoslider'>
PORTAL {" "}
          <span className='spancontenidoslider'>
            MEXBRIDGE<span className='spanpuntorojoslider'>.</span>
          </span>
        </h1>
        <div className='contenidosliderbody'>
          <div className="col-lg-12">
          
            <div className="row">
    <div className="col-lg-2"/>
    <div className="col-lg-8">
    <p className='pcontenidosliderbody'>
          Nuestro portal es un espacio virtual dedicado exclusivamente a socios 
inversionistas para abrir un puente de comunicación entre usted y sus inversiones.
          </p>
    </div>
    <div className="col-lg-2"/>
    <div className="col-lg-1"/>
    <div className=" col-lg-10 iconoscontenidosliderbody">
    <div className="row">
  <div className="col-lg-3">
    <div className="col-lg-12">
      <center>

      <img src={Icon1} className="iconcontenidosliderbody" alt="" srcset="" />
      <p className="pcontenidosliderbodybold">
      Gestión eficiente y 
      <br/>
organización
      </p>
      </center>
    </div>
    </div>
    <div className="col-lg-3">
    <div className="col-lg-12">
      <center>

      <img src={Icon2} className="iconcontenidosliderbody" alt="" srcset="" />
      <p className="pcontenidosliderbodybold">
      Informes 
      <br/>
personalizados
      </p>
      </center>
    </div>
    </div>
    <div className="col-lg-3">
    <div className="col-lg-12">
      <center>

      <img src={Icon3} className="iconcontenidosliderbody" alt="" srcset="" />
      <p className="pcontenidosliderbodybold">
      Comunicación y 
      <br/>
asesoría
      </p>
      </center>
    </div>
    </div>
    <div className="col-lg-3">
    <div className="col-lg-12">
      <center>

      <img src={Icon4} className="iconcontenidosliderbody" alt="" srcset="" />
      <p className="pcontenidosliderbodybold">
      Confidencialidad y 
      <br/>
acceso privado
      </p>
      </center>
    </div>
    </div>
  </div>
  
    </div>
    <div className="col-lg-1"/>
    <div className="col-lg-12 botoncontenidosliderbody">
      <div className="row">
        <div className="col-lg-5"></div>
        <div className="col-lg-2">
        <RouterLink  to="/choice"  style={{textDecoration: 'none', cursor: 'pointer'}}>
        <Button
              style={{background: '#8A0A12', marginBottom: '15px', fontFamily: 'Akrobat Black', fontSize: '18pt', borderRadius: '30px'}}
                
              
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                ACCESO
              </Button>
            </RouterLink>
       
        </div>
        <div className="col-lg-5"></div>
      </div>
      
    </div>
            </div>
          </div>
          
        </div>
      </div>
    <BarraFooterIndex/>
      </div>

/**
 * 
 *  
    <div class="center bgdefault" id="divPrin">
    <div class="child">
      

      <img src={Logo} alt="" srcset="" style={{width: '450px'}} />
<center>

    <div className='flex' style={{width: '400px'}}>
    <RouterLink  to="/sign-in" id='btnSocio' style={{textDecoration: 'none', cursor: 'pointer'}}>

      <button className='btn-login' style={{ display: 'flex', fontFamily: 'Akrobat Bold', cursor: 'pointer'}}> <img src={iconS} style={{width:'30px', marginRight: '15px'}}/> SOCIOS</button>
    </RouterLink>
    <RouterLink  to="/sign-in" id="btnEmpresa" style={{textDecoration: 'none', cursor: 'pointer'}}>
      <button className='btn-loginempr'style={{ display: 'flex', fontFamily: 'Akrobat Bold', cursor: 'pointer'}}> <img src={iconE} style={{width:'30px', marginRight: '15px'}}/>EMPRESAS</button>
      </RouterLink>
    </div>
</center>

    </div>
  </div>
 */



  )
}

export default Slider