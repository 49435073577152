import { Routes, Route, Navigate} from "react-router-dom";
// routes import Router from './routes';
// theme
import 'react-toastify/dist/ReactToastify.css'
import ThemeProvider from './theme';
// components
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/Login';
// import NotFound from './pages/Page404';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import DocOficial from './pages/DocOficial'
import DocFondo from './pages/DocFondo';
import Mexbridge from './pages/Mexbridge';
import SignUp from "./pages/SignUp";
import User from "./pages/User";
import PrivateRoute from "./components/PrivateRoute";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/ForgotPassword";
import Profile from "./pages/Profile";
import Ticket from "./pages/Ticket";
import Slider from "./pages/Slider";
import Edit from "./pages/Edit";
import PressKit from "./pages/PressKit";
import Choice from "./pages/Choice";
import LoginPartner from "./pages/LoginPartner";
import LoginFamily from "./pages/LoginFamily";
import LoginMexFund from "./pages/LoginMexFund";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <>
    <ThemeProvider>
      <ScrollToTop /> 
    
   
     <Routes>
     <Route path="/" element={<Navigate to="/slider" />} />
     <Route path="/sign-in" element={<Login/>}/>
     <Route path="/slider" element={<Slider/>}/>
     <Route path="/choice" element={<Choice/>}/>
     <Route path="/sign-in-partner" element={<LoginPartner/>}/>
     <Route path="/sign-in-family" element={<LoginFamily/>}/>
     <Route path="/sign-in-mexfund" element={<LoginMexFund/>}/>


     <Route path="/forgot-password" element={<ForgotPassword/>}/>
     <Route path='/dashboard' element={<PrivateRoute/>}>

     <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="app" element={<DashboardApp />} />
        <Route path="Administrativa" element={<Products />} />
        <Route path="Oficial" element={<DocOficial />} />
        <Route path="Fondo" element={<DocFondo />} />
        <Route path="Usuarios" element={<User/>} />
        <Route path="profile" element={<Profile/>} />
        <Route path="mexbridge" element={<Mexbridge />} />
        <Route path="app" element={<LogoOnlyLayout />} />
        <Route path="sign-up" element={<SignUp/>} />
        <Route path="edit" element={<Edit/>} />
        <Route path="tickets" element={<Ticket/>} />
        <Route path="press-kit" element={<PressKit/>} />
        <Route path="*" element={<Navigate to="/404" />} />
     
      </Route>
     </Route> 
     </Routes>
    </ThemeProvider>
     <ToastContainer/>
     </>
  );
}
