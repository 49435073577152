import './Nav.css'
import React from 'react'
import IconFlecha from '../logo/flecha_Grupomex.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const Nav = ({logo}) => {
  return (
    <nav className="barrahome">
        <div className="container">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-4 row">
              {
              /**
               * 
               <ArrowBackIcon style={{width:'20px', marginRight: '5px', color: 'white', marginTop: '12px'}}/>
               * 
               */
              }
              <img src={logo} alt="" className='logobarrahome' srcset="" />
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="col-lg-12">
                <div className="row">
                <div className="col-lg-4"></div>
                  <div className="col-lg-8">
                    <a href="https://www.grupomexbridge.com/" target="_blank" rel="noreferrer">
                      <button className='btnregresarbarrahome row'> <img src={IconFlecha} style={{width:'20px', marginRight: '5px'}} alt="" srcset="" /> <span>IR A GRUPO MEXBRIDGE</span></button>
                    </a>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </nav>
  )
}

export default Nav