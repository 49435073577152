import { faker } from '@faker-js/faker';
// @mui
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// sections
import {
 
  AppNewsUpdate,
 
  AppTrafficBySite,
  AppWidgetSummary,
  
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function Mexbridge() {
 // const theme = useTheme();

  return (
    <Page title="">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 1 }}>
         Grupo MexBridge
        </Typography>
        <Typography variant="h6" sx={{ mb: 5, color: 'gray' }}>
         Lee más sobre nosotros.
        </Typography>
       
        <Grid container spacing={3}>
        
          <Grid item xs={12} sm={6} md={3}>
          <RouterLink to="/dashboard/Administrativa" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="GRUPO" color="warning" icon={'eva:people-fill'} />
            </RouterLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <RouterLink to="/dashboard/Oficial" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="OPERACIÓN"  color="warning" icon={'eva:people-fill'} />
          </RouterLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <RouterLink to="/dashboard/Fondo" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="FONDOS DE INVERSIÓN"  color="warning" icon={'eva:people-fill'} />
          </RouterLink>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
          <RouterLink to="/dashboard/Fondo" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="RESPONSABILIDAD"  color="warning" icon={'eva:people-fill'} />
          </RouterLink>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="Artículos"
              list={[...Array(4)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Contacto"
              list={[
                
                {
                    url: 'https://www.grupomexbridge.com',
                    name: 'Correo',
                    
                    icon: <Iconify icon={'eva:globe-fill'} color="#1877F2" width={32} height={32} />,
                  },
               
                {
                  url: 'https://www.grupomexbridge.com',
                  name: 'Correo',
                  
                  icon: <Iconify icon={'eva:globe-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                    url: 'https://www.grupomexbridge.com',
                    name: 'Correo',
                    
                    icon: <Iconify icon={'eva:globe-fill'} color="#1877F2" width={32} height={32} />,
                  },
               
                {
                  url: 'https://www.grupomexbridge.com',
                  name: 'Correo',
                  
                  icon: <Iconify icon={'eva:globe-fill'} color="#1877F2" width={32} height={32} />,
                },
              ]}
            />
          </Grid>
 
        </Grid>
      </Container>
    </Page>
  );
}
