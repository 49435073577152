import React from 'react'
import loading from 'src/_mock/loading'
import './loading.css'
const Loading = () => {
  return (
    <div className='loadingSpinnerContainer'>
        <img src={loading.photoURL} alt="loading" style={{width: '90px'}}  />
    </div>
  )
}

export default Loading