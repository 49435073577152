/*import React from 'react'
import TicketLayout from 'src/components/TicketLayout'
import {Box,Container,Button, ButtonGroup} from '@mui/material';

const Ticket = () => {
  return (
    <Box component="main"  sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 0,
        },
      }}>
        <Container maxWidth="md">
            <center style={{marginBottom: '10px'}}>
        <ButtonGroup size="large" aria-label="large button group">
        <Button key="one" variant='contained'>PENDIENTES</Button>
  <Button key="two">TERMINADOS</Button>
      </ButtonGroup>
            </center>
      <TicketLayout/>
        </Container>
    </Box>
    
   
        
    
  )
}

export default Ticket
*/
import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const emailData = [
{
titulo: 'MEXBRIDGE OPERACIONES',
email: 'operaciones@grupomexbridge.com'
},
{
  titulo: 'PLATAFORMA',
  email: 'plataforma@grupomexbridge.com'
  },
  {
    titulo: 'MEXBRIDGE INVERSIONES',
    email: 'inversiones@grupomexbridge.com'
    },
    {
      titulo: 'MEXBRIDGE LICENCIAS',
      email: 'licencias@grupomexbridge.com'
      },
      {
        titulo: 'RELACIONES CON INVERSIONISTAS',
        email: 'relaciones.i@grupomexbridge.com'
        },
]
export default function ResponsiveGrid() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
       {emailData.map(data=>(
         <Grid item xs={2} sm={4} md={4} key={data.email  }>
         <Item>
         <a style={{ textDecoration: 'none'}} href= {`mailto:${data.email}`} >
          <h1 style={{color:'black', fontFamily: 'Akrobat Bold', fontSize: '14pt'}}>{data.titulo}</h1>
          <a style={{color: 'rgba(0,0,0,0.5)', textDecoration: 'none'}} href= {`mailto:${data.email}`} >{data.email}</a>
        </a>
         </Item>
       </Grid>
        ))}
        
      </Grid>
    </Box>
  );
}