import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import engrane from '../../_mock/engrane';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Inicio',
    icon: 'eva:home-fill',
    linkTo: '/dashboard/app',
  },
 /*
  {
    label: 'Perfil',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  /*
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
  */
  
];

// ----------------------------------------------------------------------

export default function AccountPopover({profileName}) {
  const auth = getAuth()
  const navigate = useNavigate()
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);

  const onLogout = () =>{
  auth.signOut()
  navigate('/')
  
  }
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    
    setOpen(null);
   
    
  };
  const handleExit = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('data')
    setOpen(null);
    <RouterLink to="/"/>
    window.location.reload()
    
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={engrane.photoURL} alt="photoURL"  style={{backgroundColor: 'rgb(241, 229, 22)'}}/>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
          {profileName}
          </Typography>
         
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
       
        <MenuItem  onClick={onLogout} sx={{ m: 1 }}>
          Salir
        </MenuItem>
       
      </MenuPopover>
    </>
  );
}
