import React, {useState, useEffect} from 'react'
import { getAuth  } from 'firebase/auth'
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore'
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useParams } from 'react-router-dom'
import { db } from '../firebase.config'
// material
import { Container, Typography, Grid } from '@mui/material';
// components
import Page from '../components/Page';
import { ProductList } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
import FileCard from 'src/components/FileCard';
import Loading from 'src/components/Loading';
// ----------------------------------------------------------------------

export default function PressKit() {
  //obtengo la autenticación del usuario e firebase
  const auth = getAuth()
  const params = useParams()
  //use state de los datos
  const [listings, setListings] = useState(null)
  const [filesNames, setFilesNames] = useState(null)
  //usestate loading verdadero por default
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = ('')
  //formdata que me da el nombre, email y id del usuario
 const id = auth.currentUser.uid

 const storage = getStorage();
 const folder = "Fondo"

 let files = []
 const listRef = ref(storage, `${id}/${folder}/`);

  //ejecuto al iniciar
  useEffect(()=>{

    const fileArr = []

    //funciton para obtener los datos
    const fetchFiles = async ()=>{
      await listAll(listRef)
 .then((res) => {
   res.items.forEach((itemRef) => {
    let newFileName = itemRef._location.path.replace(`${id}/${folder}/`, "")
    getDownloadURL(itemRef).then((downloadURL) => {
      
      //llamo a la function que registra los url's de los archivos en un array y paso como
      //parametro la url
      urlRegister(downloadURL, newFileName)
    }
   )
    /**
     * let newFileName = itemRef._location.path.replace(`${id}/`, "")
    
     */
    
         
    
     // All the items under listRef.
   });
 })

 


    }
    const urlRegister=  (url, newFileName)=>{
      //inserto la url de cada arvhivo hasta el final
      fileArr.push({
        name:  newFileName,
        url
         })
      console.log(files);
      fetchListings()
     //const value = url
   // setFileURL((prev => [...prev, value]))
      
      
     }
    const fetchListings = async () =>{
        try {
            //get reference
            const listingsRef = collection(db, 'users')
            //create a query
            const q = query(listingsRef, where('id', '==', id), orderBy('timestamp', 'desc'), limit(10))
            //execute query
            const querySnap = await getDocs(q)
            //Array con los datos
            const listings = []
            const filesNames = [...fileArr]
    console.log(filesNames);
            //toda la información del usuario {} la insertor en el array listings
            querySnap.forEach((doc)=>{
                return listings.push({
                    data: doc.data(),
                    files: filesNames
                }) 
            })
           
          
            //map a el array de llistings para obtener los elementos del array
            listings.map((listing)=>{
              
              //esta variable tiene el uuid que brinda firabse por usuario
              const userId = listing.data.id
             
              //otro map dentro del array para obtener cada imagen por individual
             
              return userId
            })
            
            //asigno las listings al usestate
            setListings(listings)
            setFilesNames(filesNames)
            setLoading(false)
            //hago el loading false para que muestre la informcación obtenida
          
           //imprimo el error
        } catch (error) {
            console.log(error)
        }
    } 
   
    
    //ejecuto la funcion para obtener los datos
    fetchFiles()

}, [id])
  return (
    <Page title="Dashboard: Products">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Press Kit
        </Typography>
        {loading ? <Loading/> : listings && listings.length > 0 ?(
             <>
             <Grid container spacing={3}>
               {listings.map((listing)=>(
                listing.files.map((file)=>(
                  <Grid key={file.name} item xs={12} sm={6} md={3}>
                  <FileCard title={file.name} file={file.url}/>
                 
                </Grid>
                 
                )
                  
                )
               ))}

               </Grid>
           
             </>
             ) : <p>Sin Documentación</p>}
        

        {/*
        <ProductCartWidget />
        */
       
        }
        
      </Container>
    </Page>
  );
}
