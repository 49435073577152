import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {getAuth, createUserWithEmailAndPassword} from 'firebase/auth'
import {db} from '../firebase.config'
import { setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {Box,Button,Container,TextField,Typography, Grid, Stack, IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import { paises, fondos } from '../utils/data/data'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import Loading from 'src/components/Loading'
const SignUp = () => {
  const [loading, setLoading] = useState(false) 
    // const [showPassword, setShowPassword] = useState(false)
    //useState para los países por defaul selecciono el primero
    const [valorFondo, setValorFondo] = useState(fondos[0]);
    //useState para los fondos por defaul selecciono el primero
    const [valorPais, setValorPais] = useState(paises[0])
    //useState para el valor del fondo
  const [inputValueFondo, setInputValueFondo] = useState('');
  //useState para el valor del país
  const [inputValuePais, setInputValuePais] = useState('');
  //const [fileURL, setFileURL] = useState([])
  //useState para saber cuando el auth user esta autenticado o no (se ha guardado los datos en la bd)
  const [authUser, setAuthUser] = useState(false)
  //asigno a fondo el valor dle input
  let fondo= inputValueFondo
  //asigno a pais el valor del input
  let pais = inputValuePais
  //array de los archivos que se subirán por usuario
    let files = []
//console.log(valorFondo)
//formData que contiene los campos que irán a la base de datos
    const [formData, seFormData] = useState({
      email: '',
      password: '',
      name: '',
      lastName: '',
     images: {},
     images2: {},
     images3: {}
      
      
    })
    //destructuring a formData para acceder a ellos directamente
    const {email, password, name, lastName, images, images2, images3} = formData
    //hook useNavigate para redireccionar
    const navigate = useNavigate()
    //function para guardar lo que se escribe en los inputs en los campos de formData
    const onChange = (e)=>{
      seFormData((prevState)=>({
        ...prevState,
        [e.target.id]: e.target.value
      }))
      console.log(e.target.value);
    }
    //saber si el unput de files contiene algo
    const onMutate =e =>{
      let boolean = null
      if(e.target.value === 'true'){
          boolean = true
      }
      if(e.target.value === 'false'){
          boolean = false
      }
      ///files
      if(e.target.files){
          seFormData((prevState)=>({
              ...prevState,
              images: e.target.files
          }))
      }
      //text/booleans/numbers
      if(!e.target.files){
          seFormData((prevState)=>({
              ...prevState,
              [e.target.id]: boolean ?? e.target.value
          }))
      }
  }


  const onMutate2 =e =>{
    let boolean = null
    if(e.target.value === 'true'){
        boolean = true
    }
    if(e.target.value === 'false'){
        boolean = false
    }
    ///files
    if(e.target.files){
        seFormData((prevState)=>({
            ...prevState,
            images2: e.target.files
        }))
    }
    //text/booleans/numbers
    if(!e.target.files){
        seFormData((prevState)=>({
            ...prevState,
            [e.target.id]: boolean ?? e.target.value
        }))
    }
}

const onMutate3 =e =>{
  let boolean = null
  if(e.target.value === 'true'){
      boolean = true
  }
  if(e.target.value === 'false'){
      boolean = false
  }
  ///files
  if(e.target.files){
      seFormData((prevState)=>({
          ...prevState,
          images3: e.target.files
      }))
  }
  //text/booleans/numbers
  if(!e.target.files){
      seFormData((prevState)=>({
          ...prevState,
          [e.target.id]: boolean ?? e.target.value
      }))
  }
}
  //functino para subir los archivos al storage
  const onUpload = async e =>{
    e.preventDefault()
    setLoading(true)
    //obtengo la autenticación por google
    const auth = getAuth()
    //consulto el email y ocntraseña
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
     //store
  const storeData = async (image) =>{
    return new Promise((resolve, reject)=>{
      //obtengo el servicio de storage
      const storage = getStorage()
      //asigno el mismo nombre del archivo que suben al storage
      const fileName = `${image.name}`
      const directory = 'Administrativa'
      //creo la carpeta con el uuId generado por google y adentro inserto los archivos
      const storageRef = ref(storage, `${user.uid}/${directory}/${fileName}`)
      //subo los archvivos
      const uploadTask = uploadBytesResumable(storageRef, image)
      uploadTask.on('state_changed',
  (snapshot) => {
     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
      default:
        console.log("no upload")
    }
  },
  (error) => {
    reject(error)
    //console.log(error);
  },
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //obtengo la url del arvhico
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      resolve(downloadURL);
      //llamo a la function que registra los url's de los archivos en un array y paso como
      //parametro la url
      urlRegister(downloadURL)
    }
   )
   
  },
  
);
    }
    )
   
   }
   //function para popular el array de files con las urls
   const urlRegister=  (url)=>{
    //inserto la url de cada arvhivo hasta el final
    files.push(url)
   //const value = url
 // setFileURL((prev => [...prev, value]))
    
    
   }

   const storeData2 = async (image) =>{
    return new Promise((resolve, reject)=>{
      //obtengo el servicio de storage
      const storage = getStorage()
      //asigno el mismo nombre del archivo que suben al storage
      const fileName = `${image.name}`
      const directory = 'Oficial'
      //creo la carpeta con el uuId generado por google y adentro inserto los archivos
      const storageRef = ref(storage, `${user.uid}/${directory}/${fileName}`)
      //subo los archvivos
      const uploadTask = uploadBytesResumable(storageRef, image)
      uploadTask.on('state_changed',
  (snapshot) => {
     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
      default:
        console.log("no upload")
    }
  },
  (error) => {
    reject(error)
    //console.log(error);
  },
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //obtengo la url del arvhico
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      resolve(downloadURL);
      //llamo a la function que registra los url's de los archivos en un array y paso como
      //parametro la url
      
    }
   )
   
  },
  
);
    }
    )
   
   }

   const storeData3 = async (image) =>{
    return new Promise((resolve, reject)=>{
      //obtengo el servicio de storage
      const storage = getStorage()
      //asigno el mismo nombre del archivo que suben al storage
      const fileName = `${image.name}`
      const directory = 'Fondo'
      //creo la carpeta con el uuId generado por google y adentro inserto los archivos
      const storageRef = ref(storage, `${user.uid}/${directory}/${fileName}`)
      //subo los archvivos
      const uploadTask = uploadBytesResumable(storageRef, image)
      uploadTask.on('state_changed',
  (snapshot) => {
     const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
      default:
        console.log("no upload")
    }
  },
  (error) => {
    reject(error)
    //console.log(error);
  },
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //obtengo la url del arvhico
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      resolve(downloadURL);
      //llamo a la function que registra los url's de los archivos en un array y paso como
      //parametro la url
     
    }
   )
   
  },
  
);
    }
    )
   
   }
 //por cada rchivo llamo a la fuinction storeData y paso el arhvio
   const imageUrls = await Promise.all(
    [...images].map((image)=> storeData(image))
   )

   const imageUrls2 = await Promise.all(
    [...images2].map((image)=> storeData2(image))
   )
   const imageUrls3 = await Promise.all(
    [...images3].map((image)=> storeData3(image))
   )
   console.log(imageUrls)
   console.log(imageUrls2)
   console.log(imageUrls3)
   //autorizo al usuario
   setAuthUser(true)
 
   /*
   llamo a la funcion para insertar los datos del usuario en la base de datos junto con las
   urls de los archivos  y le paso comno parametro el uid del usurio generado anteriormente
    */
  onSubmit(user.uid);
  }

    const onSubmit = async (userId)=>{
     
      
    
 
       // const auth = getAuth()
     //   const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      //  const user = userCredential.user
      /*  updateProfile(auth.currentUser, {
          displayName: name,
          
        })*/
        //hago una copia de formData, agrego a parte de el contenido de formData, el país,
        //tipo de fondo, el ID y los archivos
        const formDataCopy = {
          ...formData,
          tipoFondo: fondo,
          tipoUsuario: pais,
          id: userId,
         images: files,
         images2: files, 
         images3: files

        }
        
        console.log(files)
        //elimino la contraseña ingresada, por seguridad
        delete formDataCopy.password
        //agrego la fecha y hoira de la creación
        formDataCopy.timestamp = serverTimestamp()
        //setDoc para ingresar los datos a la bd, instancio la db, creo la coleccion users,
        //creo el indice por cada usuario e ingreso los datos de formDataCopy a la base datos
        await setDoc(doc(db, 'users', userId), formDataCopy)
        console.log(formDataCopy)
        //redirijo al dashboard
        setLoading(false)
        navigate('/dashboard/Usuarios')

     
       
    }
   
  return (
    <>
      {
        loading && (
          <Loading/>
        )
      }
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
         
        }}
      >
        <Container maxWidth="md">
          <Link to="/dashboard/Usuarios">
            <Button
             style={{color: 'rgb(136,18,6)'}}
              startIcon={<ArrowBackIcon fontSize="small" />}
            >
             
            </Button>
          </Link>
          <form onSubmit={onUpload}>
            <Box sx={{ my: 3 }}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                Crear nuevo usuario
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Ingresa la información del usuario.
              </Typography>
            </Box>
            <Box
     
      sx={{
        '& > :not(style)': { width: '43ch' },
      }}
      noValidate
      autoComplete="off"
    >
<TextField
            sx={{mr: 1}}
           
           
            label="Nombre"
            margin="normal"
            name="firstName"
            id='name' 
            value={name} 
            onChange={onChange}
            variant="outlined"
            
          />      
<TextField
            
            
           
            label="Apellidos"
            margin="normal"
            name="firstName"
            id='lastName' 
            value={lastName} 
            onChange={onChange}
            variant="outlined"
            
          />        
    </Box>
  <Grid container sx={{mt:1}}>
  <Autocomplete
        value={valorPais}
        onChange={(event, newValue) => {
          setValorPais(newValue);
        }}
        inputValue={inputValuePais}
        onInputChange={(event, newInputValue) => {
          setInputValuePais(newInputValue);
        }}
        id="paises"
        options={paises}
        sx={{ width: '43ch',  mr: 1}}
        renderInput={(params) => <TextField {...params} label="Tipo" />}
      />
     <Autocomplete
        value={valorFondo}
        onChange={(event, newValue) => {
          setValorFondo(newValue);
        }}
        inputValue={inputValueFondo}
        onInputChange={(event, newInputValue) => {
          setInputValueFondo(newInputValue);
        }}
        id="fondos"
        options={fondos}
        sx={{ width: '43ch',  }}
        renderInput={(params) => <TextField {...params} label="Tipo de fondo" />}
      />
      </Grid>
      

      <Grid container sx={{mt:0}}>
      <TextField
              
             
              
              label="Email Address"
              margin="normal"
              name="email"
              sx={{ width: '43ch',  mr: 1}}
              type="email"
              id='email'
               value={email} 
               onChange={onChange}
              variant="outlined"
            />


<TextField
             
              
             sx={{ width: '43ch',  }}
             label="Password"
             margin="normal"
             name="password"
            
             type="password"
             id='password'
              value={password}
               onChange={onChange}
             variant="outlined"
           />


    
      </Grid>
           
            
           
            <Stack direction="row" sx={{pt: 1}} alignItems="center" spacing={'auto'}>
      <Button variant="contained" component="label" endIcon={<DriveFolderUploadIcon />}>
      Documentos Administrativos
      <input
            className='formInputFile'
            type='file'
            id='images'
            onChange={onMutate}
            max='6'
            accept='.pdf, .png'
            multiple
            required
            hidden
          />
      </Button>
     
      
      <Button variant="contained" component="label" endIcon={<DriveFolderUploadIcon />}>
      Documentos Oficiales
      <input
            className='formInputFile'
            type='file'
            id='images2'
            onChange={onMutate2}
            max='6'
            accept='.pdf, .png'
            multiple
            required
            hidden
          />
      </Button>
     

      <Button variant="contained" component="label" endIcon={<DriveFolderUploadIcon />}>
      Documentos del Fondo
      <input
            className='formInputFile'
            type='file'
            id='images3'
            onChange={onMutate3}
            max='6'
            accept='.pdf, .png'
            multiple
            required
            hidden
          />
      </Button>
     
    </Stack>
       
              
                      

          
                     

         
          {
            authUser && (
              <Box sx={{ py: 2 }}>
              <Button
              style={{background: 'rgb(136,18,6)'}}
                
              
               
                size="large"
                type="submit"
                variant="contained"
              >
               Registrar Usuario
              </Button>
            </Box>
            )
             
          }
           {
            !authUser && (
              <Box sx={{ py: 2 }}>
              <Button
              style={{background: 'rgb(136,18,6)', float: 'right'}}
                
              
                
                size="large"
                type="submit"
                variant="contained"
              >
               Subir Archivos y Registrar
              </Button>
            </Box>
            )
           }
          
          </form>
        </Container>
      </Box>
    </>
  );
  }

export default SignUp
