// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Inicio',
    path: '/dashboard/app',
    icon: getIcon('eva:home-fill'),
  },
 
  {
    title: 'Resumen Financiero',
    path: '/dashboard/Administrativa',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Resumen Operativo',
    path: '/dashboard/Oficial',
    icon: getIcon('eva:pie-chart-fill'),
  },
  {
    title: 'Documentación',
    path: '/dashboard/Fondo',
    icon: getIcon('eva:folder-fill'),
  },
  {
    title: 'Usuarios',
    path: '/dashboard/Usuarios',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Press Kit',
    path: '/dashboard/press-kit',
    icon: getIcon('eva:folder-fill'),
  },
  {
    title: 'Contacto',
    path: '/dashboard/tickets',
    icon: getIcon('eva:person-fill'),
  },
  /*
  {
    title: 'Grupo Mexbridge',
    path: '/dashboard/MexBridge',
    icon: getIcon('eva:globe-fill'),
  },
  */
  /*
  {
    title: 'login',
    path: '/login',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon('eva:person-add-fill'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill'),
  },
  */
];

export default navConfig;
