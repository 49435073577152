import * as React from 'react';
import './choice.css'
import $ from 'jquery'

import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

import LogoPortal from '../logo/Portal-38.svg'
import { useEffect } from 'react';
import Nav from 'src/components/Nav';
import BarraFooter from 'src/components/BarraFooter';
import IconEficios from '../logo/Edificios.svg'
import IconPersonas from '../logo/Personas.svg'
import IconFolder from '../logo/Folder_MB.svg'
const Choice = () => {
    const IconData=[
        {
            id: 'icon1',
            icono: IconEficios,
            texto1: 'Socio',
            texto2: 'PARTNER SOLUTION.',
            link: '/sign-in-partner'
        },
        {
            id: 'icon2',
            icono: IconPersonas,
            texto1: 'Socio',
            texto2: 'FAMILY OFFICE.',
            link: '/sign-in-family'
        },
        {
            id: 'icon3',
            icono: IconFolder,
            texto1: 'Socio',
            texto2: 'MEXBRIDGE FUND.',
            link: '/sign-in-mexfund'
        }
    ]
    //#9a9c9d
  const Slider2=()=>{
  
    $( "#icon1" ).mouseenter(function() {
     
      $("#divPrin").removeClass("choicedivmenubg")
      
      $("#divPrin").addClass("choicedivpartnerbg")
    }).mouseleave(function() {
      $("#divPrin").removeClass("choicedivpartnerbg")
      $("#divPrin").addClass("choicedivmenubg")
    });
  
    $( "#icon2" ).mouseenter(function() {
      $("#divPrin").removeClass("choicedivmenubg")
      $("#divPrin").addClass("choicedivfamilybg")
    }).mouseleave(function() {
      $("#divPrin").removeClass("choicedivfamilybg")
      $("#divPrin").addClass("choicedivmenubg") 
     });

     $( "#icon3" ).mouseenter(function() {
        $("#divPrin").removeClass("choicedivmenubg")
        $("#divPrin").addClass("choicedivfundbg")
      }).mouseleave(function() {
        $("#divPrin").removeClass("choicedivfundbg")
        $("#divPrin").addClass("choicedivmenubg") 
       });
    
  }
  useEffect(() => {
    Slider2()
  }, [])
  
  

  return (
 <div >
    <Nav logo={LogoPortal} />
    <div id='divPrin' className='choicediv '>
        <div className="container">
        <div className="divbienvenidochoice">
                        <h1 className='h1choice'><span className="borderletterchoice" >Bien</span>venido<span class="spanpuntorojoslider">.</span></h1>
                    </div>
        </div>
    </div>
    <div className=" icondiv">
       <div className="container">
        <div className="col-lg-12 pt-5">
            <div className="row">
            {IconData.map((icon) => (

<div className="col-lg-4 pointer hoveropacitychoice" id={icon.id} >
<RouterLink  to={icon.link}  style={{textDecoration: 'none', cursor: 'pointer'}}>
    <div className="col-lg-12 p-0">
        <div className="row p-0">
            <div className="col-lg-4" >
            <div className="radiusiconchoice">

<img src={icon.icono} className="iconogridchoice" alt="" srcset="" />
</div>
            </div>
            <div className="col-lg-8 pr-0">
                <div className="pl-2 pt-3 pr-0">

            <p className="texto1iconogridchoice">
            {icon.texto1}
        </p>
        <p className="texto2iconogridchoice">
            {icon.texto2}
        </p>
                </div>
            </div>
        </div>
    </div>
    
</RouterLink>
</div>
      ))}
            </div>
        </div>
       </div>
    </div>
    {/**
     * 
     *  <div className="container">
        <div className="col-lg-12">
            <div className="row">
                <div className="col-lg-4">
                    <div className="divbienvenidochoice">
                        <h1 className='h1choice'><span className="borderletterchoice" >Bien</span>venido<span class="spanpuntorojoslider">.</span></h1>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="diviconoschoice">
                        <div className="col-lg-12">
                            <div className="row">
                            {IconData.map((icon) => (

<div className="col-lg-4 pointer hoveropacitychoice" id={icon.id}>
<RouterLink  to={icon.link}  style={{textDecoration: 'none', cursor: 'pointer'}}>

    <center>
        <div className="radiusiconchoice">

        <img src={icon.icono} className="iconogridchoice" alt="" srcset="" />
        </div>
        <p className="texto1iconogridchoice">
            {icon.texto1}
        </p>
        <p className="texto2iconogridchoice">
            {icon.texto2}
        </p>
    </center>
</RouterLink>
</div>
      ))}
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     * 
     */}
   
    <BarraFooter texto1={"Soporte y asistencia técnica"} texto2={"Contacto e información"}/>
 </div>


  )
}

export default Choice