// @mui
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';

// import account from '../_mock/account';

// sections
import {
 

  AppWidgetSummary,
  
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------


export default function DashboardApp() {
 // const theme = useTheme();


  return (
    <Page title="">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Bienvenido de nuevo<span style={{color: 'rgb(138, 10, 18)'}}>.</span>
        </Typography>
       
        <Grid container spacing={3}>
        
          <Grid item xs={12} sm={6} md={4}>
          <RouterLink to="/dashboard/Administrativa" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="Resumen Financiero" color="secondary" icon={'ant-design:folder-filled'} />
            </RouterLink>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
          <RouterLink to="/dashboard/Oficial" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="Resumen Operativo"  color="secondary" icon={'ant-design:folder-filled'} />
          </RouterLink>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
          <RouterLink to="/dashboard/Fondo" style={{textDecoration: 'none'}}>
            <AppWidgetSummary title="Presentación"  color="secondary" icon={'ant-design:folder-filled'} />
          </RouterLink>
          </Grid>
    
        </Grid>
      </Container>
    </Page>
    
  );
}
