import './Nav.css'
import React from 'react'
import IconFlecha from '../logo/flecha_Grupomex.svg'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link as RouterLink } from 'react-router-dom';

const NavLogin = ({logo}) => {
  return (
    <nav className="barrahome">
        <div className="container">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-4 row">
            <RouterLink  to="/choice"  style={{textDecoration: 'none', cursor: 'pointer', display: 'flex'}}>
               <HomeRoundedIcon style={{width:'20px', marginRight: '5px', color: 'black', marginTop: '15px'}}/>
              
              <img src={logo} alt="" className='logobarrahome' srcset="" />
              </RouterLink>
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="col-lg-12">
                <div className="row">
                <div className="col-lg-4"></div>
                  <div className="col-lg-8">
                    <a href="https://www.grupomexbridge.com/" target="_blank" rel="noreferrer">
                      <button className='btnregresarbarrahome row'> <img src={IconFlecha} style={{width:'20px', marginRight: '5px'}} alt="" srcset="" /> <span>IR A GRUPO MEXBRIDGE</span></button>
                    </a>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </nav>
  )
}

export default NavLogin