import React, {useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';
import pdf from 'src/_mock/pdf';

// /static/mock-images/products/producto_${setIndex}.png
// utils
// import { fCurrency } from '../../../utils/formatNumber';
// components
// import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FileCard =({  title, file } )  =>{
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <Card>
      <Box sx={{ pt: '50%', position: 'relative' }}>
        
        <ProductImgStyle alt="pdf" src={pdf.photoURL} />
      </Box>

      <Stack spacing={1} sx={{ pb: 3, pt: 1, px: 3 }}>
      
          <Typography variant="subtitle2" noWrap sx={{textAlign: 'center'}}>
            {title}
          </Typography>
          
          <Button 
         
          sx={{mt: 1}}
          fullWidth
          size="small"
          type="button"
          variant="outlined"
           onClick={handleClickOpen}
>Abrir</Button>
        

       
      </Stack>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', height: '8vh' }}>
          <Toolbar>
          <Button autoFocus color="inherit">
          {title}
            </Button>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
             
            </Typography>
           
            <Button autoFocus color="inherit" onClick={handleClose}>
              X
            </Button>
           
          </Toolbar>
        </AppBar>
       <Box>

        <div style={{ height: '91vh',width: '100vw'}}>
        
        <embed src={file} type="application/pdf" width="100%" height="100%" />

       
        </div>
      
        
      
       </Box>
      </Dialog>
    </Card>
  );
}
export default FileCard