import React, {useState, useEffect} from 'react'
import {getAuth} from 'firebase/auth'
import {Box,Container,Typography, Link} from '@mui/material';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore'
import { db } from '../firebase.config'
import { Link as Redirect } from 'react-router-dom'


const Profile = () => {
    const [useName, setUserName] = useState('')
    const [userLastName, setUserLastName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userFondo, setUserFondo] = useState('')
    const [userTipo, setUserTipo] = useState('')
    //obtengo la autenticación del usuario e firebase
    const auth = getAuth()
    //use state de los datos
    //usestate loading verdadero por default
    const [loading, setLoading] = useState(true)
    //formdata que me da el nombre, email y id del usuario
   const id = auth.currentUser.uid
  
    //ejecuto al iniciar
    useEffect(()=>{
      //funciton para obtener los datos
      const fetchListings = async () =>{
          try {
              //get reference
              const listingsRef = collection(db, 'users')
              //create a query
              const q = query(listingsRef, where('id', '==', id), orderBy('timestamp', 'desc'), limit(10))
              //execute query
              const querySnap = await getDocs(q)
              //Array con los datos
              const listings = []
                let userName = ''
                let userEmail = ''
                let userLastName = ''
                let userFondo = ''
                let userTipo = ''
              const filesNames = []
              //toda la información del usuario {} la insertor en el array listings
              querySnap.forEach((doc)=>{
                  return listings.push({
                      data: doc.data()
                  }) 
              })
              //map a el array de llistings para obtener los elementos del array
              listings.map((listing)=>{
                //esta variable tiene el uuid que brinda firabse por usuario
                const userId = listing.data.id         
                 //otro map dentro del array para obtener cada imagen por individual
                listing.data.images.map((image)=>{
                  ////OBTENER NOMBRE DEL ARCHIVO
                  //Elimino la primera parte de la URL para dejar el nombre del archivo y remplazo el id estático por el USERID 
                  let newImage = image.replace(`https://firebasestorage.googleapis.com/v0/b/mbdashboard-bd9ad.appspot.com/o/${userId}%2F`, "")
                 //elimino lo restante de la url despues de ?alt dejando solo el nombre de los archivos
                  let finalName = newImage.substring(0, newImage.indexOf('?alt'));
                  filesNames.push({
                    name : finalName,
                    url: image
                  }                   
                )  
                  return newImage
                })
                return userId
              })
              listings.map((listing)=>{
                const user = listing.data.name
                const lastName = listing.data.lastName
                const email = listing.data.email
                const tipoFondo = listing.data.tipoFondo
                const tipoUsuario = listing.data.tipoUsuario
                userName = user
                userLastName = lastName
                userEmail = email
                userFondo = tipoFondo
                userTipo = tipoUsuario
                return userName
              })
              //asigno las listings al usestate

              
              setUserName(userName)
              setUserLastName(userLastName)
              setUserEmail(userEmail)
              setUserFondo(userFondo)
              setUserTipo(userTipo)
              //hago el loading false para que muestre la informcación obtenida
              setLoading(false)
              console.log(useName)
             //imprimo el error
          } catch (error) {
              console.log(error)
          }
      } 
     
      
      //ejecuto la funcion para obtener los datos
      fetchListings()
  }, [id])

  return (
    <Box
    component="main"
    
  >

    <Container maxWidth="md">
     
     
        <Box>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            Perfil
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Aquí podrás ver tus datos personales.
          </Typography>
        </Box>
        <Box
        
 
  sx={{
    '& > :not(style)': { width: '43ch' },
  }}
  noValidate
  autoComplete="off"
>
    <Box sx={{display: 'flex'}}>
    <Typography
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            Nombre:
          </Typography> 
          <Typography
          sx={{mx: '10px'}}
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            {useName}
          </Typography>
    </Box>
    <Box sx={{display: 'flex'}}>
    <Typography
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            Apellido:
          </Typography> 
          <Typography
          sx={{mx: '10px'}}
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            {userLastName}
          </Typography>
    </Box>
    <Box sx={{display: 'flex'}}>
    <Typography
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            E-mail:
          </Typography> 
          <Typography
          sx={{mx: '10px'}}
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            {userEmail}
          </Typography>
    </Box>
    <Box sx={{display: 'flex'}}>
    <Typography
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            Tipo de Fondo Afiliado:
          </Typography> 
          <Typography
          sx={{mx: '10px'}}
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            {userFondo}
          </Typography>
    </Box>
    <Box sx={{display: 'flex'}} style={{cursor: 'not-allowed'}}>
    <Typography
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            Tipo de Usuario:
          </Typography> 
          <Typography
          sx={{mx: '10px'}}
            color="textSecondary"
            gutterBottom
            variant="body1"
          >
            {userTipo}
          </Typography>
    </Box>
    <Redirect to='/forgot-password'>
    <Link style={{float: 'right'}}>
           ¿Tu información es erronea?
          </Link>
    
      </Redirect>
</Box>

  


       
        
       
   
          
                  

      
                 

     
     
      
   
    </Container>
  </Box>
  )
}

export default Profile