import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import React, {useState} from 'react'
import { toast } from 'react-toastify'
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Button, TextField ,Link } from '@mui/material';
import Page from '../components/Page';
import Imglogin from '../logo/imagen_login.png'
import useResponsive from '../hooks/useResponsive';
import { Link as Redirect } from 'react-router-dom'

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  }));
  
  
  
  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
  }));
  
  const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
  }));
  
const ForgotPassword = () => {
    const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const onChange = e =>setEmail(e.target.value)
  const onSubmit = async (e) =>{
    e.preventDefault()
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      toast.success('Email enviado')
       navigate('/sign-in', { replace: true });
    } catch (error) {
      toast.error('No se puedo enviar el email')
    }
  }

  const mdUp = useResponsive('up', 'md');

  return (

      


      <Page title="Login">
      <RootStyle>
        
        {mdUp && (
          <SectionStyle style={{backgroundImage: `url( ${Imglogin})`, backgroundSize: 'cover'}}>
           <img src="/static/illustrations/logoblanco_crm.svg" alt="login" style={{width: '70%', margin: 'auto'}}/>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Cambio de Contraseña
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 0 }}>Recibirás un correo con las instrucciones, si no lo ves, puede estar en spam.</Typography>


            <form onSubmit={onSubmit}>
            <TextField
              
              fullWidth
              
              label="Email Address"
              margin="normal"
              name="email"
             
              type="email"
              id='email'
               value={email} 
               onChange={onChange}
              variant="outlined"
            />
         
       
        
        <div className="signInBar" style={{marginTop: '5px'}}>
          
          <Button
              style={{background: 'rgb(136,18,6)'}}
                
              
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
               RESET
              </Button>
              <Redirect to='/sign-in'>
              <Link style={{float: 'right'}}>
           Iniciar sesión
          </Link>

              </Redirect>
        </div>
        </form>

            
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
 
  )
}

export default ForgotPassword