import { filter, isError } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';


import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';

//firestore
import { collection, getDocs, query, where, orderBy as OrBy, limit, doc, deleteDoc } from 'firebase/firestore'
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { useParams } from 'react-router-dom'
import { db } from '../firebase.config'
import { getAuth  } from 'firebase/auth'


// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

import Loading from 'src/components/Loading';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'company', label: 'Fondo', alignRight: false },
  { id: 'role', label: 'ID', alignRight: false },
  { id: 'isVerified', label: 'Email', alignRight: false },
  { id: 'status', label: 'Tipo', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [open, setOpen] = useState(false);

 
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [listings, setListings] = useState(null)
  //usestate loading verdadero por default
  const [loading, setLoading] = useState(true)
  const auth = getAuth()
  const id = auth.currentUser.uid

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchUsers = async () =>{
    try {
        //get reference
        const listingsRef = collection(db, 'users')
        //create a query
        const q = query(listingsRef, where('tipoUsuario', '==', 'usuario'), OrBy('timestamp', 'desc'), limit(10))
        //execute query
        const querySnap = await getDocs(q)
        //Array con los datos
        const listings = []
        //toda la información del usuario {} la insertor en el array listings
        querySnap.forEach((doc)=>{
            return listings.push({
                data: doc.data(),
               
            }) 
        })


       
      
        //map a el array de llistings para obtener los elementos del array
        
        
        //asigno las listings al usestate
        setListings(listings)
        setLoading(false)
        //hago el loading false para que muestre la informcación obtenida
      
       //imprimo el error
    } catch (error) {
        console.log(error)
    }
    
} 
  useEffect(()=>{
  fetchUsers()
  }, [id])
  console.log(listings)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listings.map((n) => n.data.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listings.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;
  

const deleteUser = async ( user) =>{
  await deleteDoc(doc(db, "users", user));
  fetchUsers()

}

  return (
    
    <Page title="User">
       {loading ? <Loading/> : listings && listings.length > 0 ?(
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarios
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/sign-up" startIcon={<Iconify icon="eva:plus-fill" />}>
            Añadir Usuario
          </Button>
        </Stack>

        <Card>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={listings.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                {
                          listings.map((user)=>(
                            <TableRow
                            hover
                            key={user.data.id}
                            tabIndex={-1}
                            role="checkbox" 
                          >

                            <TableCell padding="checkbox">
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                 {user.data.name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{user.data.tipoFondo}</TableCell>
                            <TableCell align="left">{user.data.id}</TableCell>
                            <TableCell align="left">{user.data.email}</TableCell>
                            <TableCell align="left">
                              <Label variant="ghost" color='success'>
                               {user.data.tipoUsuario}
                              </Label>
                            </TableCell>
    
                            <TableCell align="right">
                              <UserMoreMenu click={(e)=>deleteUser( user.data.id)} userFondo={user.data.tipoFondo} userEmail={user.data.email} userId={user.data.id} userName={user.data.name}/>
                            </TableCell>
                          </TableRow>
                          ))
                        }
                     
                    
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
       ): <p>Sin usuarios</p>}
    </Page>
  );
}
