import React from 'react'
import './userEdit.css'
import account from 'src/_mock/account'
const UserEdit = ({userFondo, userEmail, userId, userName}) => {
  return (
    <div>
        
<div className="layout">
  <div className="profile">
    <div className="profile__picture"><img src={account.photoURL} alt="ananddavis"/></div>
    <div className="profile__header">
      <div className="profile__account">
        <h4 className="profile__username">{userName}</h4>
      </div>
      <div className="profile__edit"><a className="profile__button" href="#">Editar</a></div>
    </div>
    <div className="profile__stats">
      <div className="profile__stat">
        <div className="profile__icon profile__icon--gold"><i className="fas fa-wallet"></i></div>
        <div className="profile__value">E-mail:
          <div className="profile__key">{userEmail}</div>
        </div>
      </div>
      <div className="profile__stat">
        <div className="profile__icon profile__icon--blue"><i className="fas fa-signal"></i></div>
        <div className="profile__value">Fondo:
          <div className="profile__key">{userFondo}</div>
        </div>
      </div>
      <div className="profile__stat">
        <div className="profile__icon profile__icon--pink"><i className="fas fa-heart"></i></div>
        <div className="profile__value">ID:
          <div className="profile__key">{userId}</div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default UserEdit