import React, {useState, useEffect} from 'react'
import { getAuth  } from 'firebase/auth'
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore'
import { db } from '../../firebase.config'
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [useName, setUserName] = useState('')
  const [open, setOpen] = useState(false);
  //obtengo la autenticación del usuario e firebase
  const auth = getAuth()
  //use state de los datos
  const [listings, setListings] = useState(null)
  const [filesNames, setFilesNames] = useState(null)
  //usestate loading verdadero por default
  const [loading, setLoading] = useState(true)
  //formdata que me da el nombre, email y id del usuario
 const id = auth.currentUser.uid

  //ejecuto al iniciar
  useEffect(()=>{
    //funciton para obtener los datos
    const fetchListings = async () =>{
        try {
            //get reference
            const listingsRef = collection(db, 'users')
            //create a query
            const q = query(listingsRef, where('id', '==', id), orderBy('timestamp', 'desc'), limit(10))
            //execute query
            const querySnap = await getDocs(q)
            //Array con los datos
            const listings = []
              let userName = ''
            const filesNames = []
            //toda la información del usuario {} la insertor en el array listings
            querySnap.forEach((doc)=>{
                return listings.push({
                    data: doc.data()
                }) 
            })
            //map a el array de llistings para obtener los elementos del array
            listings.map((listing)=>{
              
              //esta variable tiene el uuid que brinda firabse por usuario
              const userId = listing.data.id
              //obtengo el nombre
              
              //otro map dentro del array para obtener cada imagen por individual
              listing.data.images.map((image)=>{
                ////OBTENER NOMBRE DEL ARCHIVO
                //Elimino la primera parte de la URL para dejar el nombre del archivo y remplazo el id estático por el USERID 
                let newImage = image.replace(`https://firebasestorage.googleapis.com/v0/b/mbdashboard-bd9ad.appspot.com/o/${userId}%2F`, "")
               //elimino lo restante de la url despues de ?alt dejando solo el nombre de los archivos
                let finalName = newImage.substring(0, newImage.indexOf('?alt'));
                filesNames.push({
                  name : finalName,
                  url: image
                }
                 
              )
             
              /*
                filesNames.map(nombre=>{
                  console.log(nombre.name)
                 })
                 */
                return newImage
              })
              return userId
            })
            listings.map((listing)=>{
              const user = listing.data.name
              userName = user
              return userName
            })
            //asigno las listings al usestate
            setListings(listings)
            setFilesNames(filesNames)
            setUserName(userName)
            //hago el loading false para que muestre la informcación obtenida
            setLoading(false)
            console.log(useName)
           //imprimo el error
        } catch (error) {
            console.log(error)
        }
    } 
   
    
    //ejecuto la funcion para obtener los datos
    fetchListings()
}, [id])

  return (
    <RootStyle>
      <DashboardNavbar profileName={useName} onOpenSidebar={() => setOpen(true)} />
      {
        
      }
      <DashboardSidebar profileName={useName} id={id} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
