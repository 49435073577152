// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyAuC4XhH2aF7U24YZ1dRBAn7rTTkeEvm20",

  authDomain: "mbdashboard-bd9ad.firebaseapp.com",

  projectId: "mbdashboard-bd9ad",

  storageBucket: "mbdashboard-bd9ad.appspot.com",

  messagingSenderId: "174973732293",

  appId: "1:174973732293:web:fed60136ccbec89f9f37ac"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore()