import React from 'react'
import IconCorreo from '../logo/Correo.svg'
import IconSoporte from '../logo/Soporte.svg'
import './BarraFooter.css'

const BarraFooter = ({texto1, texto2}) => {
  return (
    <div className='barrafooter'>
        <div className="col-lg-12">
            <div className="row">
                <div className="col-lg-6">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-3">
                                <p className="pbarrafooter row">
<img src={IconSoporte} alt="" style={{width:'20px'}} className="mr-2" srcset="" />  {texto1}
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <p className="pbarrafooter row">

<img src={IconCorreo} alt="" style={{width:'20px'}} className="mr-2" srcset="" /> {texto2}

                                

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-3">
                                <p className="pbarrafooter">
<center>

                                Privacidad
</center>
                                </p>
                                </div>
                            <div className="col-lg-3">
                            <p className="pbarrafooter">
                                <center>

Seguridad
                                </center>

</p>
                            </div>
                            <div className="col-lg-3">
                            <p className="pbarrafooter">
                                <center>

                            Terminos y condiciones
                                </center>

</p>
                            </div>
                            <div className="col-lg-3">
                            <p className="pbarrafooter">
                                <center>

                            © 2023 Portal MexBridge
                                </center>

</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default BarraFooter