// import { Link as RouterLink } from 'react-router-dom';
// @mui
import React, {  useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { Link, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
import { toast } from 'react-toastify'
import { Link as Redirect } from 'react-router-dom'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import SendIcon from '@mui/icons-material/Send';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import LogoPortal from '../logo/Portal-38.svg'
import IconFolder from '../logo/Folder_MB.svg'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom'
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Imglogin from '../logo/logo.png'
import LogoLogin from '../logo/logo_plataforma.svg'
import Logoblack from '../logo/logo_black.svg'
import Logoobs from '../logo/logo_obsidian.svg'
import imgBlack from '../logo/blackbay_port_mar.png'
import imgObs from '../logo/obsidian_port_mar.png'
import imgD from '../logo/p_priv_enero.png'
import img from '../logo/empresas_port_mar.png'
import './LoginVarios.css'
import BarraFooter from "src/components/BarraFooter";
import NavLogin from "src/components/NavLogin";
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));



const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  
  flexDirection: 'column',
  justifyContent: 'right',
  margin: theme.spacing(5, 0, 0, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,

  float: 'right',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  padding: theme.spacing(20,0),
}));

// ----------------------------------------------------------------------

export default function LoginPartner() {
  const [formData, seFormData] = useState({
    email: '',
    password: ''
  })
  const [business, setBusiness] = useState(0)
  //destructuring del formData para acceder a los keys directo
  const {email, password} = formData

  //hook useNavigate para redirigir cuando el login se exitoso
  const navigate = useNavigate()
  //function para obtener lo que se esribe en los inputs (email, password)
  const onChange = (e)=>{
    seFormData((prevState)=>({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }
  //function al iniciar sesión
  const onSubmit = async e =>{
    //prevengo el refresh
    e.preventDefault()
    try{
      //consulto los emails con getAuth
      const auth = getAuth()
      //obtengo los email y password existentes en la bd
    const userCredentials = await signInWithEmailAndPassword(auth, email, password)
    //si el login fue exitoso
    if(userCredentials.user){
        toast.success('Succesful Login')
        //redirijo al dashboard
        navigate('/dashboard/app', { replace: true });
    }
    //sino muestro que no se pudo iniciar sesión
    }catch (error){
      toast.error('Bad User Credentials')
    }
    
  }

 
 // const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');



useEffect(() => {
  
  if(email.includes('blackbay') ){
  setBusiness(1)
   }
  else if(email.includes('grupomexbridge')){
    setBusiness(2)
  }
  else if(email.includes('obsidian')){
    setBusiness(3)
  }
  else{
  setBusiness(0)
  }
  console.log(business);
}, [email])
/**
 * 
 * 
  
 */
  return (
    
    <Page title=""  className="logindiv logindivfundbg">
       <NavLogin logo={LogoPortal}/>
      <div className="col-lg-12">
    <div className="row">
      {
      /**
       *  <div className="col-lg-5" style={{  backgroundImage: business==1 ? `url( ${imgBlack})` : ( business==2 ? `url( ${img})` : (business==3) ? `url( ${imgObs})` : `url( ${img})`) , backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
, height: '100vh', backgroundPosition: 'bottom'}}>
         
      <a href="https://www.grupomexbridge.com/" style={{textDecoration: 'none'}}>
           <button  class="buttonIcon" style={{ margin: 'auto', marginLeft: '150px', marginTop: '50px'}}>
           <ArrowBackIcon />
        <span style={{fontFamily: 'Akrobat Light', marginTop: '2.5px', marginLeft: '5px'}}>REGRESAR A <b style={{fontFamily: 'Akrobat Bold'}}>GRUPO MEXBRIDGE</b></span>
      </button>
            
          </a>
          <center style={{marginTop: '81vh'}}>

          <a href="mailto:plataforma@grupomexbridge.com" style={{color: 'white', textDecoration: 'none', fontFamily: 'Akrobat Light'}}>
              <HeadphonesIcon />
                <span className='pl-2 pt-0' >plataforma@grupomexbridge.com</span>
              </a>
          </center>
      </div>
       * 
       */
      }
      <div className="col-lg-7">
         
     
         
      </div>
      <div className="col-lg-4 bgdivlogin">
        <center>

      <form onSubmit={onSubmit} style={{ height: '70vh', borderRadius: '10px', marginTop: '15vh', marginBottom: '15vh', padding: '50px'}}>
      <div className="col-lg-12">

         <div className="row">
            
<div className="col-lg-4">

          <img src={IconFolder} style={{width: '50px', float: 'right'}}  alt="" srcset="" />
</div>
<div className="col-lg-8">

      <Typography variant="h3" gutterBottom style={{color: 'black', fontFamily: 'Akrobat Black', textAlign: 'left', lineHeight: '0.75'}}>
            <span class="" style={{borderLeft: '4px solid #8A0A12', paddingLeft: '30px'}}>Socio <br/><span style={{fontFamily: 'Akrobat Light', borderLeft: '4px solid #8A0A12', paddingLeft: '30px', fontSize: '14pt' }}>MEXBRIDGE FUND</span></span>
            </Typography>
</div>
           
         </div>
      </div>

           
            
            {/**
             *   {
             (!email.includes('grupomexbridge')) && (
              <>
               <TextField
                 
                 fullWidth
                 
                 label="Company"
                 margin="normal"
                 disabled
                
                 type="text"
                 id='business'
                 
                  
                 variant="outlined"
               />
              </>
              )
            }
           {
           (email.includes('grupomexbridge')) && (
           <>
            <TextField
              
              fullWidth
              
              label="Grupo Mexbridge"
              margin="normal"
              disabled
             
              type="text"
              id='business'
              
               
              variant="outlined"
            />
           </>
           )
           }
             * 
             */}
          
          
           
            <TextField
              style={{fontFamily: 'Akrobat', backgroundColor: 'white', borderRadius: '10px', border: 'none'}}
              fullWidth
              
              label="Correo electrónico"
              margin="normal"
              name="email"
             
              type="email"
              id='email'
               value={email} 
               onChange={onChange}
              variant="filled"
            />
          {/**
           * 
           *           <input type="email" placeholder='Email' id='email' value={email} onChange={onChange} className="emailInput" />
                    <input type={'password'} className="passwordInput" placeholder='password' id='password' value={password} onChange={onChange}/>
<p className="signInText">
            Iniciar Sesión
          </p>                    <button className="signInButton">
          Login
          </button>
           */}
        <div className="passwordInputDiv">

        <TextField
              style={{fontFamily: 'Akrobat', backgroundColor: 'white', borderRadius: '10px', border: 'none'}}
              fullWidth
              
             label="Contraseña"
             margin="normal"
             name="password"
            
             type="password"
             id='password'
              value={password}
               onChange={onChange}
             variant="filled"
             
           />
       
        </div>
        
        <div className="signInBar" style={{marginTop: '5px'}}>
      
        <Button
              style={{background: '#8A0A12', marginBottom: '15px', fontFamily: 'Akrobat Black', fontSize: '14pt'}}
                
              
                  fullWidth
                  size="large"
                type="submit"
                variant="contained"
              >
               Ingresar
              </Button>   
       
              
        
              <Redirect to='/forgot-password' >
              <Link style={{float: 'right', textDecoration: 'none', fontFamily: 'Akrobat',  color: 'black'}}>
           Olvidé mi contraseña
          </Link>

              </Redirect>
           


           
             
            
        </div>
        </form>
        </center>
      </div>
    </div>

      </div>
      <BarraFooter texto1={"Soporte y asistencia técnica"} texto2={"Contacto e información"}/>
   
    </Page>
  );
}
